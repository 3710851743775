import {Component} from '@angular/core';
import {MatIconRegistry} from '@angular/material/icon';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent {

    constructor(matIconRegistry: MatIconRegistry,
                domSanitizer: DomSanitizer) {
        matIconRegistry.addSvgIcon(
            'compact_grid',
            domSanitizer.bypassSecurityTrustResourceUrl('assets/compact-grid.svg'));
        matIconRegistry.addSvgIcon(
            'wrapped_grid',
            domSanitizer.bypassSecurityTrustResourceUrl('assets/wrapped-grid.svg'));
    }
}
