import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthenticationService } from '../services/authentication.service';
import { NavigationService } from '@app/core/services/navigation.service';

@Injectable({ providedIn: 'root' })
export class AuthenticationGuard implements CanActivate {
  constructor(
    private navigationService: NavigationService,
    private authenticationService: AuthenticationService
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const authentication = this.authenticationService.authenticationValue;
    if (authentication) {
      return true;
    }

    // not logged in so redirect to login page with the return url
    this.navigationService.navigatToLogin({
      queryParams: {
        returnUrl: state.url
      }
    });

    return false;
  }
}
