<mat-card-title>Pdf Metadata</mat-card-title>
<mat-card-content>
    <form [formGroup]="form" class="metadata-form">
        <mat-form-field>
            <input type="number" matInput placeholder="Start Page" formControlName="startPage" required
                   autofocus>
            <mat-error *ngIf="form.hasError('required', 'startPage')">
                Please enter start page
            </mat-error>
        </mat-form-field>
        <mat-checkbox formControlName="loops">Loops</mat-checkbox>
    </form>
</mat-card-content>
<mat-card-actions>
    <button color="primary" mat-raised-button [disabled]="form.invalid" (click)="submit()">Save Changes</button>
</mat-card-actions>
