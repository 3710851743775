import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { ClientService } from '@app/routes/client/services/client.service';
import { ClientResult } from '@app/routes/client/models/client-result';
import { Observable } from 'rxjs';
import { NavigationService } from '@app/core/services/navigation.service';

@Injectable({ providedIn: 'root' })
export class ClientGuard implements CanActivate {

  private _client: ClientResult;

  get client() {
    return this._client;
  }

  constructor(
    private clientService: ClientService,
    private navigationService: NavigationService
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> {

    let clientId = parseInt(route.params.clientId);
    if (!clientId) {
      this.navigationService.navigateToHome();
      return false;
    }

    return new Observable<boolean>((observer) => {
      this.clientService.get(clientId).subscribe(client => {
        this._client = client;
        observer.next(true);
      }, err => {
        observer.next(false);
        this.navigationService.navigateToHome();
      }, () => {
        observer.complete();
      })
    });
  }

  clear() {
    this._client = null;
  }
}
