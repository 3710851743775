import { NavigationGroup } from "./navigation-group";

export class Constants {

  public static readonly DefaultPageSize = 20;

  public static readonly ClientIdParamKey = ":clientId";
  public static readonly DevelopmentIdParamKey = ':developmentId';

  //public static readonly ClientContextKey = 'ThinkbdwClientContext';
  //public static readonly DevelopmentContextKey = 'ThinkbdwDevelopmentContext';
  public static readonly AssetPath = '/content/userfiles/assets/';

  public static readonly UserNavigations: NavigationGroup[] = [
    {
      title: "Users", url: "/user"
    },
    {
      title: "Clients", url: "/client"
    },
    {
      title: "Room Definitions", url: "/roomDefinition"
    }];

  public static readonly ClientUserNavigations: NavigationGroup[] = [
    {
      title: "Plots",
      url: "/clientPlot/list"
    },
    {
      title: "Email Logs",
      url: "/emailLog"
    }
  ];

  public static readonly ClientNavigations: NavigationGroup[] = [
    {
      title: "Developments",
      url: ":clientId/development"
    },
    {
      title: "Development Groups",
      url: ":clientId/developmentGroup"
    },
    {
      title: "Email Logs",
      url: ":clientId/emailLog"
    },
    {
      title: "Email Management",
      url: ":clientId/emailinfo"
    }];

  public static readonly DevelopmentNavigations: NavigationGroup[] = [
    {
      title: "Assets",
      url: ":clientId/:developmentId/asset-management",
    },
    {
      title: "Plot Types",
      url: ":clientId/:developmentId/plotType"
    },
    {
      title: "Plots",
      url: ":clientId/:developmentId/plot"
    },
    {
      title: "Plot Containers",
      url: ":clientId/:developmentId/plotContainer"
    },
    {
      title: "Galleries",
      url: ":clientId/:developmentId/gallery",
    },
    {
      title: "Navigations",
      url: ":clientId/:developmentId/menu"
    },
    {
      title: "Local Area",
      url: ":clientId/:developmentId/localArea",
    },
    {
      title: "Content Pages",
      url: ":clientId/:developmentId/contentPages",
    },
    {
      title: "Development Phases",
      url: ":clientId/:developmentId/developmentPhases",
    },
    {
      title: "Gdpr",
      url: ":clientId/:developmentId/gdpr"
    },
    {
      title: "Theme",
      url: ":clientId/:developmentId/theme"
    },
    {
      title: "XML Import",
      url: ":clientId/:developmentId/xmlImport"
    },
    {
      title: "Room Configurations",
      items: [
        {
          title: "List Room Configurations",
          url: ":clientId/:developmentId/roomConfiguration"
        },
        {
          title: "Create a New Room Configuration",
          url: ":clientId/:developmentId/roomConfiguration/create"
        },
        {
          title: "Room Confirmation Setting",
          url: ":clientId/:developmentId/roomConfirmationSetting"
        }
      ]
    },
    {
      title: "VR Spectator Settings",
      url: ":clientId/:developmentId/vrSpectator"
    },
    {
      title: "3D Site Plan",
      url: ":clientId/:developmentId/threeDSitePlan"     
    },
    {
      title: "Development Settings",
      url: ":clientId/:developmentId/developmentSettings"
    }
  ];

  public static readonly ColorPickerColors: string[] = [
    '#FF6633',
    '#FFB399',
    '#FF33FF',
    '#FFFF99',
    '#00B3E6',
    '#E6B333',
    '#3366E6',
    '#999966',
    '#99FF99',
    '#B34D4D',
    '#80B300',
    '#809900',
    '#E6B3B3',
    '#6680B3',
    '#66991A',
    '#FF99E6',
    '#CCFF1A',
    '#FF1A66',
    '#E6331A',
    '#33FFCC',
    '#66994D',
    '#B366CC',
    '#4D8000',
    '#B33300',
    '#CC80CC',
    '#66664D',
    '#991AFF',
    '#E666FF',
    '#4DB3FF',
    '#1AB399',
    '#E666B3',
    '#33991A',
    '#CC9999',
    '#B3B31A',
    '#00E680',
    '#4D8066',
    '#809980',
    '#E6FF80',
    '#1AFF33',
    '#999933',
    '#FF3380',
    '#CCCC00',
    '#66E64D',
    '#4D80CC',
    '#9900B3',
    '#E64D66',
    '#4DB380',
    '#FF4D4D',
    '#99E6E6',
    '#6666FF',
  ];

  public static readonly DEFAULT_ELLIPSE_OPTIONS = {
    selectable: false,
    hasControls: false,
    opacity: 0.2,
    stroke: 'blue',
    strokeWidth: 1
  };

  public static readonly DEFAULT_TEXT_OPTIONS = {
    fill: "white",
    textAlign: "center",
    fontFamily: "Arial",
    selectable: false,
    hasControls: false,
    fontSize: 12,
    originX: 'center',
    originY: 'center',
    //fontWeight: 'bold'
  };
}
