import { Component, OnInit } from '@angular/core';

import { UserContextService } from '@app/shared/services/user-context.service';
import { ClientGuard } from '@app/core/guards/client.guard';
import { DevelopmentGuard } from '@app/core/guards/development.guard';
import { NavigationService } from '@app/core/services/navigation.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  constructor(private userContextService: UserContextService,
    private clientGuard: ClientGuard,
    private developmentGuard: DevelopmentGuard,
    private navigationService: NavigationService) {
  }

  ngOnInit() {

    if (!this.userContextService.UserContext) {
      this.navigationService.navigatToLogin();
      return;
    }

    if (this.userContextService.UserContext.clientId) {
      this.navigationService.navigateToClientUserHome();
      return;
    }

    if (!this.clientGuard.client) {
      this.navigationService.navigateToHome();
      return;
    }
    
    const clientId = this.clientGuard.client.id;

    if (!this.developmentGuard.development) {
      this.navigationService.navigateToClientHome(clientId);
      return;
    }

    const developmentId = this.developmentGuard.development.id;

    this.navigationService.navigateToDevelopmentHome(clientId, developmentId);
  }
}
