import {
  Component,
  OnInit,
  Input,
  HostBinding,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
  ViewChild,
  ElementRef,
  OnDestroy,
  ChangeDetectionStrategy,
  ChangeDetectorRef
} from '@angular/core';
import {map, tap, switchMap, startWith, take} from 'rxjs/operators';
import {NgfmConfig} from '../models/ngfm-config';
import {NgfmFolder} from '../models/ngfm-folder';
import {NgfmFile} from '../models/ngfm-file';
import {NgfmItem} from '../models/ngfm-item';
import {NgfmDialogService} from '../dialog/ngfm-dialog.service';
import {NgfmApi} from '../connectors/ngfm-api';
import {BehaviorSubject, fromEvent, Observable, of, Subscription} from 'rxjs';

@Component({
  selector: 'ngfm-browser',
  templateUrl: './ngfm-browser.component.html',
  styleUrls: ['./ngfm-browser.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NgfmBrowserComponent implements OnInit, OnChanges, OnDestroy {
  @HostBinding('class.ngfm-browser') public _hostClass = true;
  @Input() pick: 'file' | 'folder' | null;
  @Input() config$: Observable<NgfmConfig>;
  @Output() navigated: EventEmitter<NgfmFolder> = new EventEmitter();
  @Output() picked: EventEmitter<NgfmItem> = new EventEmitter();
  @Input() folder: NgfmFolder;
  gridCols$: Observable<number>;
  children$: BehaviorSubject<NgfmFolder[] | NgfmFile[] | NgfmItem[]>;
  selectedFiles: NgfmFile[] = [];
  @ViewChild('widthSource', {static: true}) widthSource: ElementRef;
  private subscriptions: Subscription[];
  hideTools = false;
  isOver = false;

  public isAllFilesSelected = false;
  public containsAnyFiles = false;

  constructor(
    private ngfm: NgfmApi,
    private cdRef: ChangeDetectorRef,
    public dialog: NgfmDialogService
  ) {
  }

  ngOnInit() {
    this.subscriptions = [this.ngfm.navigate.subscribe(this.navigate.bind(this))];
    this.gridCols$ = !window ? of(8) : fromEvent(window, 'resize').pipe(
      startWith(8),
      switchMap(() => this.getColCount()),
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  getColCount(): Observable<number> {
    const containerWidth = this.widthSource.nativeElement.offsetWidth;
    return this.config$.pipe(
      take(1),
      map(config => {
        this.ngfm.config = config; // TODO: Ugly ass code, just needed to get config there quick, fix later! xD
        return Math.floor(containerWidth / config.listItemSize);
      }));
  }


  refresh(folder: NgfmFolder = null, filterObject: any = {}) {
    // const filter = this.pick ? { itemType: this.pick } : {}; // @Meditation: I donno if this is even a good idea. Better let user see what files are in there?
    this.children$ = this.ngfm.ls(folder, filterObject).pipe(
      map(items => {
        if (this.pick === 'folder') {
          return items.filter(item => item.isFolder);
        }

        this.containsAnyFiles = items.filter(item => item.isFile).length > 0;

        return items;
      }),
      tap(items => {
        // this.children = items;
        this.selectedFiles = this.selectedFiles.map(selFile => items.find(item => item.id === selFile.id) as NgfmFile).filter(foundFile => !!foundFile).map(selFile => {
          selFile.selected = true;
          return selFile;
        });
        this.cdRef.markForCheck();
      })) as BehaviorSubject<NgfmItem[]>;
  }

  uploadDialog(folder: NgfmFolder, files: NgfmFile[]) {
    this.dialog.uploadDialog(folder, files).subscribe();
  }

  mkDir(folder: NgfmFolder) {
    this.dialog.openPrompt('Folder Name', '', '').then(folderName => {
      if (!folderName) {
        return;
      }
      this.ngfm.mkSubDir(folder, folderName).subscribe();
    });
  }

  clicked(item: NgfmItem) {
    if (item.isFolder) {
      return this.navigate(item as NgfmFolder);
    }

  }

  navigate(folder: NgfmFolder) {
    // this.children = [];
    this.navigated.next(folder);
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('folder' in changes) {
      this.refresh(this.folder);
    }
  }

  selectionChange(file: NgfmFile) {
    if (this.pick === 'file') {
      this.choose(file);
      return;
    }

    const idx = this.selectedFiles.indexOf(file);
    if (idx > -1) {
      this.selectedFiles.splice(idx, 1);
    } else {
      this.selectedFiles.push(file);
    }
    this.selectedFiles = [...this.selectedFiles];
    file.selected = !file.selected;
  }

  selectAll() {
    this.children$.pipe(take(1)).subscribe(children => {
      const allFiles: NgfmFile[] = children.filter(item => item.isFile) as NgfmFile[];
      this.selectedFiles = this.selectedFiles.length === allFiles.length ? [] : [...allFiles as NgfmFile[]];
      allFiles.forEach(file => file.selected = this.selectedFiles.indexOf(file) > -1);
      this.isAllFilesSelected = this.selectedFiles.length == allFiles.length;
    });
  }

  clearAll() {
    this.children$.pipe(take(1)).subscribe(children => {
      const allFiles: NgfmFile[] = children.filter(item => item.isFile) as NgfmFile[];
      this.selectedFiles = [];
      allFiles.forEach(file => file.selected = false);
    });
  }


  choose(item: NgfmItem) {
    //ev.stopPropagation();
    this.picked.emit(item);
  }

  dropped($event: DragEvent) {
    this.isOver = false;
    $event.preventDefault();
    const files = !!$event.dataTransfer.items ? this.arrayFrom($event.dataTransfer.items)
        .filter(item => item.kind === 'file')
        .map(item => new NgfmFile(this.folder, item.getAsFile()))
      : [];

    this.uploadDialog(this.folder, files);

    if ($event.dataTransfer.items) {
      $event.dataTransfer.items.clear();
    } else {
      $event.dataTransfer.clearData();
    }
  }

  arrayFrom(items) {
    const a = [];
    for (let i = 0; i < items.length; i++) {
      a.push(items[i]);
    }
    return a;
  }

  dragOver($event: DragEvent) {
    this.isOver = true;
    $event.preventDefault();
  }

  dragLeave($event: DragEvent) {
    this.isOver = false;
    $event.preventDefault();
  }
}
