<form fxLayout="row" fxLayoutGap="10px" class="search-form" [formGroup]="searchForm" (ngSubmit)="onSearch()">
  <div fxFlex="1 1 auto">
    <mat-form-field>
      <input matInput type="text" placeholder="{{placeHolder}}" formControlName="searchKey">
      <button type="button" mat-button *ngIf="searchForm.controls.searchKey.value" matSuffix mat-icon-button aria-label="Clear" (click)="clearSearchKey()">
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>
  </div>
  <button fxFlayoutAlign="end" button="submit" class="search-button" mat-raised-button color="primary">
    Search
  </button>
</form>
