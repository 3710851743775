import {Injectable} from '@angular/core';
import {HttpRequest, HttpHandler, HttpEvent, HttpInterceptor} from '@angular/common/http';
import {Observable} from 'rxjs';
import {AuthenticationService} from '../services/authentication.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {

    constructor(private authenticationService: AuthenticationService) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const authentication = this.authenticationService.authenticationValue;
        if (authentication && authentication.token) {
            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${authentication.token}`
                }
            });
        }

        return next.handle(request);
    }
}
