import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AuthenticationModel} from '../models/authentication-model';
import {AuthenticationResult} from '../models/authentication-result';
import {map} from 'rxjs/operators';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class AuthenticationService {

    private readonly storageKey = 'authentication';
    private readonly authenticationSubject: BehaviorSubject<AuthenticationResult>;
    public readonly authentication: Observable<AuthenticationResult>;

    constructor(private httpClient: HttpClient) {
        this.authenticationSubject = new BehaviorSubject<AuthenticationResult>(
            JSON.parse(localStorage.getItem(this.storageKey)));
        this.authentication = this.authenticationSubject.asObservable();
    }

    public get authenticationValue(): AuthenticationResult {
        return this.authenticationSubject.value;
    }

    public login(model: AuthenticationModel) {
        return this.httpClient.post<AuthenticationResult>("api/authentication/authenticate", model)
            .pipe(map(result => {
                    if (result && result.token) {
                        localStorage.setItem(this.storageKey, JSON.stringify(result));
                        this.authenticationSubject.next(result);
                    }
                    return result;
                }
            ));
    }

    public logout() {
        localStorage.removeItem(this.storageKey);
        this.authenticationSubject.next(null);
    }
}
