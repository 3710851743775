import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {

  get Router() {
    return this.router;
  }

  constructor(
    private router: Router
  ) { }

  public navigate(route: string, options?: any) {
    this.router.navigate([route], options);
  }

  public navigatToLogin(options?: any) {
    this.navigate('/login', options);
  }

  public navigateToClientUserHome() {
    this.navigate('/clientPlot/list');
  }

  public navigateToHome() {
    this.navigate('/client');
  }

  public navigateToClientHome(clientId: number) {
    this.navigate(`${clientId}/development`);
  }

  public navigateToDevelopmentHome(clientId: number, developmentId: number) {
    this.navigate(`${clientId}/${developmentId}/plot`);
  }
}
