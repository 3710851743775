import { NgModule } from "@angular/core";
import { GenericSearchComponent } from '@app/shared/components/generic-search/generic-search.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { CommonMaterialModule } from '../common-material/common-material.module';

@NgModule({
  declarations: [
    GenericSearchComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    CommonMaterialModule
  ],
  exports: [
    GenericSearchComponent
  ]
})
export class GenericSearchModule {

}
