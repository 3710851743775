import { Injectable } from "@angular/core";
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { DevelopmentService } from '@app/routes/development/services/development.service';
import { DevelopmentResult } from '@app/routes/development/models/development-result';
import { Observable } from 'rxjs';
import { NavigationService } from '@app/core/services/navigation.service';

@Injectable({ providedIn: 'root' })
export class DevelopmentGuard implements CanActivate {

  private _development: DevelopmentResult;

  get development() {
    return this._development;
  }

  constructor(
    private developmentService: DevelopmentService,
    private navigationService: NavigationService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> {

    let clientId = parseInt(route.params.clientId);
    if (!clientId) {
      this.navigationService.navigateToHome();
      return false;
    }

    let developmentId = parseInt(route.params.developmentId);
    if (!developmentId) {
      this.navigationService.navigateToClientHome(clientId);
      return false;
    }

    return new Observable<boolean>((observer) => {
      this.developmentService.get(developmentId).subscribe(development => {
        this._development = development;
        if (clientId !== development.clientId) {
          this.navigationService.navigateToClientHome(clientId);
          observer.next(false);
        } else {
          observer.next(true);
        }
      }, err => {
        this.navigationService.navigateToClientHome(clientId);
        observer.next(false);
      }, () => {
        observer.complete();
      })
    });
  }

  clear() {
    this._development = null;
  }
}
