import { Injectable } from "@angular/core";

import { AuthenticationService } from '@app/core/services/authentication.service';
import {UserResult} from "@app/routes/user/models/user-result.model";

@Injectable()
export class UserContextService {

  constructor(private authenticationService: AuthenticationService) {

  }
  public cachedUser : UserResult;

  get UserContext() {
    if (this.authenticationService.authenticationValue) {
      this.cachedUser = this.authenticationService.authenticationValue.user;
      return this.cachedUser;
    }
    return null;
  }

  clear() {
    this.authenticationService.logout();
    this.cachedUser = null;
  }
}
