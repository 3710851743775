import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthenticationService } from '../services/authentication.service';
import { NavigationService } from '@app/core/services/navigation.service';

@Injectable({ providedIn: 'root' })
export class ClientUserGuard implements CanActivate {
  constructor(
    private navigationService: NavigationService,
    private authenticationService: AuthenticationService
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    if (this.authenticationService.authenticationValue.user.clientId) {
      return true;
    }

    this.navigationService.navigatToLogin({
      queryParams: {
        returnUrl: state.url
      }
    });

    return false;
  }
}
