import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModuleWithProviders } from '@angular/compiler/src/core';
import { NgfmRouteComponent } from './route/ngfm-route.component';
import { NgfmBrowserComponent } from './browser/ngfm-browser.component';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule, MatProgressBar } from '@angular/material/progress-bar';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';

import { NgfmUploadDialogComponent } from './upload-dialog/ngfm-upload-dialog.component';
import { NgfmDialogHeaderComponent } from './dialog-header/ngfm-dialog-header.component';
import { NgfmBrowserItemToolsComponent } from './browser-item-tools/ngfm-browser-item-tools.component';
import { FormsModule } from '@angular/forms';
import { NgfmBrowserDialogComponent } from './browser-dialog/ngfm-browser-dialog.component';
import { NgfmDialogComponent } from './dialog/ngfm-dialog.component';
import { NgfmDialogService } from './dialog/ngfm-dialog.service';
import { NgfmApi } from './connectors/ngfm-api';
import { NGFM_CONNECTOR } from './connectors/constants';
import { NgfmMemoryConnector } from './connectors/memory/ngfm-memory.connector';
import { HttpClientModule } from '@angular/common/http';
import { NgfmBreadcrumbsComponent } from './breadcrumbs/ngfm-breadcrumbs.component';
import { NgfmDownloadComponent } from './download/ngfm-download.component';
import { NgfmProgressSnackComponent } from './progress-snack/ngfm-progress-snack.component';
import { MatIconModule } from '@angular/material/icon';
import { MetadataDialogComponent } from '@app/routes/asset-management/metadata-dialog/metadata-dialog.component';
import { PdfMetadataComponent } from '@app/routes/asset-management/metadata-dialog/pdf-metadata/pdf-metadata.component';
import { VideoMetadataComponent } from '@app/routes/asset-management/metadata-dialog/video-metadata/video-metadata.component';
import { CommonMaterialModule } from '@app/shared/modules/common-material/common-material.module';
import { NgfmGridListComponent } from './grid-list/ngfm-grid-list.component';

@NgModule({
  imports: [
    CommonModule,
    CommonMaterialModule,
    FormsModule,
    HttpClientModule,
  ],
  exports: [
    NgfmBrowserComponent,
    NgfmBrowserItemToolsComponent,
    NgfmGridListComponent,
    NgfmBreadcrumbsComponent
  ],
  declarations: [
    NgfmRouteComponent,
    NgfmBrowserComponent,
    NgfmBrowserDialogComponent,
    NgfmUploadDialogComponent,
    NgfmDialogComponent,
    NgfmDialogHeaderComponent,
    NgfmBrowserItemToolsComponent,
    NgfmBreadcrumbsComponent,
    NgfmDownloadComponent,
    NgfmProgressSnackComponent,
    MetadataDialogComponent,
    PdfMetadataComponent,
    VideoMetadataComponent,
    NgfmGridListComponent,
  ],
  providers: [
    { provide: NGFM_CONNECTOR, useClass: NgfmMemoryConnector }
  ]
})
export class NgfmModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: NgfmModule,
      providers: [NgfmApi, NgfmDialogService]
    };
  }
}
