import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// guards
import { ClientGuard } from './core/guards/client.guard';
import { DevelopmentGuard } from './core/guards/development.guard';
import { AdminUserGuard } from './core/guards/admin-user.guard';
import { AuthenticationGuard } from './core/guards/authentication.guard';
import { ClientUserGuard } from './core/guards/client-user.guard';

// components
import { LoginComponent } from './core/routes/login/login.component';
import { HomeComponent } from './routes/home/home.component';


const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'user',
    loadChildren: () => import('./routes/user/user.module').then(m => m.UserModule),
    canActivate: [AuthenticationGuard, AdminUserGuard]
  },
  {
    path: 'client',
    loadChildren: () => import('./routes/client/client.module').then(m => m.ClientModule),
    canActivate: [AuthenticationGuard, AdminUserGuard]
  },
  {
    path: ':clientId/development',
    loadChildren: () => import('./routes/development/development.module').then(m => m.DevelopmentModule),
    canActivate: [AuthenticationGuard, AdminUserGuard, ClientGuard]
  },
  {
    path: ':clientId/developmentGroup',
    loadChildren: () => import('./routes/development-group/development-group.module').then(m => m.DevelopmentGroupModule),
    canActivate: [AuthenticationGuard, AdminUserGuard, ClientGuard]
  },
  {
    path: ':clientId/emailinfo',
    loadChildren: () => import('./routes/emailinfo/emailinfo.module').then(m => m.EmailInfoModule),
    canActivate: [AuthenticationGuard, AdminUserGuard, ClientGuard]
  },
  {
    path: ':clientId/emailLog',
    loadChildren: () => import('./routes/emaillog/emaillog.module').then(m => m.EmailLogModule),
    canActivate: [AuthenticationGuard]
  },
  {
    path: ':clientId/:developmentId/plotType',
    loadChildren: () => import('./routes/plot-type/plot-type.module').then(m => m.PlotTypeModule),
    canActivate: [AuthenticationGuard, AdminUserGuard, ClientGuard, DevelopmentGuard]
  },
  {
    path: ':clientId/:developmentId/plot',
    loadChildren: () => import('./routes/plot/plot.module').then(m => m.PlotModule),
    canActivate: [AuthenticationGuard, AdminUserGuard, ClientGuard, DevelopmentGuard]
  },
  {
    path: ':clientId/:developmentId/plotContainer',
    loadChildren: () => import('./routes/plot-container/plot-container.module').then(m => m.PlotContainerModule),
    canActivate: [AuthenticationGuard, AdminUserGuard, ClientGuard, DevelopmentGuard]
  },
  {
    path: ":clientId/:developmentId/theme",
    loadChildren: () => import('./routes/theme/theme.module').then(m => m.ThemeModule),
    canActivate: [AuthenticationGuard, AdminUserGuard, ClientGuard, DevelopmentGuard]
  },
  {
    path: ':clientId/:developmentId/menu',
    loadChildren: () => import('./routes/menu-management/menu-management.module').then(m => m.MenuManagementModule),
    canActivate: [AuthenticationGuard, AdminUserGuard, ClientGuard, DevelopmentGuard]
  },
  {
    path: ':clientId/:developmentId/asset-management',
    loadChildren: () => import('./routes/asset-management/asset-management.module').then(m => m.AssetManagementModule),
    canActivate: [AuthenticationGuard, AdminUserGuard, ClientGuard, DevelopmentGuard]
  },
  {
    path: ':clientId/:developmentId/gallery',
    loadChildren: () => import('./routes/gallery-management/gallery-management.module').then(m => m.GalleryManagementModule),
    canActivate: [AuthenticationGuard, AdminUserGuard, ClientGuard, DevelopmentGuard]
  },
  {
    path: ':clientId/:developmentId/localArea',
    loadChildren: () => import('./routes/local-area/local-area.module').then(m => m.LocalAreaModule),
    canActivate: [AuthenticationGuard, AdminUserGuard, ClientGuard, DevelopmentGuard]
  },
  {
    path: ':clientId/:developmentId/contentPages',
    loadChildren: () => import('./routes/content-pages/content-pages.module').then(m => m.ContentPagesModule),
    canActivate: [AuthenticationGuard, AdminUserGuard, ClientGuard, DevelopmentGuard]
  },
  {
    path: ':clientId/:developmentId/developmentPhases',
    loadChildren: () => import('./routes/development-phases/development-phase.module').then(m => m.DevelopmentPhaseModule),
    canActivate: [AuthenticationGuard, AdminUserGuard, ClientGuard, DevelopmentGuard]
  },
  {
    path: ':clientId/:developmentId/xmlImport',
    loadChildren: () => import('./routes/xmlImport/xmlImport.module').then(m => m.XmlImportModule),
    canActivate: [AuthenticationGuard, AdminUserGuard, ClientGuard, DevelopmentGuard]
  },
  {
    path: ':clientId/:developmentId/gdpr',
    loadChildren: () => import('./routes/gdpr/gdpr.module').then(m => m.GdprModule),
    canActivate: [AuthenticationGuard, AdminUserGuard, ClientGuard, DevelopmentGuard]
  },
  {
    path: ':clientId/:developmentId/vrSpectator',
    loadChildren: () => import('./routes/vrSpectator/vrSpectatorSettings.module').then(m => m.VrSpectatorSettingsModule),
    canActivate: [AuthenticationGuard, AdminUserGuard, ClientGuard, DevelopmentGuard]
  },
  {
    path: ':clientId/:developmentId/roomConfiguration',
    loadChildren: () => import('./routes/room-configuration/room-configuration.module').then(m => m.RoomConfigurationModule),
    canActivate: [AuthenticationGuard, AdminUserGuard, ClientGuard, DevelopmentGuard]
  },
  {
    path: ':clientId/:developmentId/threeDSitePlan',
    loadChildren: () => import('./routes/three-d-site-plan/three-d-site-plan.module').then(m => m.ThreeDSitePlanModule),
    canActivate: [AuthenticationGuard, AdminUserGuard, ClientGuard, DevelopmentGuard]
  },
  {
    path: ":clientId/:developmentId/developmentSettings",
    loadChildren: () => import('./routes/development-settings/development-settings.module').then(m => m.DevelopmentSettingsModule),
    canActivate: [AuthenticationGuard, AdminUserGuard, ClientGuard, DevelopmentGuard]
  },
  {
    path: ':clientId/:developmentId/roomConfirmationSetting',
    loadChildren: () => import('./routes/room-confirmation-setting/room-confirmation-setting.module').then(m => m.RoomConfirmationSettingModule),
    canActivate: [AuthenticationGuard, AdminUserGuard, ClientGuard, DevelopmentGuard]
  },
  {
    path: 'roomDefinition',
    loadChildren: () => import('./routes/room-definition/room-definition.module').then(m => m.RoomDefinitionModule),
    canActivate: [AuthenticationGuard, AdminUserGuard]
  },
  {
    path: 'clientPlot',
    loadChildren: () => import('./routes/plot/plot.module').then(m => m.PlotModule),
    canActivate: [AuthenticationGuard, ClientUserGuard]
  },
  {
    path: 'emailLog',
    loadChildren: () => import('./routes/emaillog/emaillog.module').then(m => m.EmailLogModule),
    canActivate: [AuthenticationGuard]
  },
  {
    path: '',
    component: HomeComponent,
    canActivate: [AuthenticationGuard]
  },
  {
    path: ':clientId',
    loadChildren: () => import('./routes/development/development.module').then(m => m.DevelopmentModule),
    canActivate: [AuthenticationGuard, AdminUserGuard, ClientGuard]
  },
  {
    path: ':clientId/:developmentId',
    loadChildren: () => import('./routes/plot/plot.module').then(m => m.PlotModule),
    canActivate: [AuthenticationGuard, AdminUserGuard, ClientGuard, DevelopmentGuard]
  },
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
