import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {NgfmFile} from '@app/shared/modules/ngfm/models/ngfm-file';
import {AssetConnectorService} from '@app/routes/asset-management/services/asset-connector.service';
import {MetadataResult} from '@app/routes/asset-management/models/metadata.result';

@Component({
    selector: 'app-metadata-dialog',
    templateUrl: './metadata-dialog.component.html',
    styleUrls: ['./metadata-dialog.component.scss']
})
export class MetadataDialogComponent implements OnInit {
    public file: NgfmFile;
    metadata: MetadataResult = {pdfMetadata: null, videoMetadata: null};

    constructor(@Inject(MAT_DIALOG_DATA) dialogData: any,
                public dialogRef: MatDialogRef<MetadataDialogComponent>,
                private assetConnector: AssetConnectorService) {
        this.file = dialogData.file;
    }

    ngOnInit() {
        this.assetConnector.getMetadata(this.file.id).subscribe(
            value => this.metadata = value
        );
    }
}
