import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthenticationService } from '../../services/authentication.service';
import { AuthenticationModel } from '../../models/authentication-model';
import { ActivatedRoute } from '@angular/router';
import { first } from 'rxjs/operators';
import { TopbarTitleService } from "@app/shared/services/topbar-title.service";
import { NavigationService } from '@app/core/services/navigation.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {
  form: FormGroup = new FormGroup({
    username: new FormControl('', Validators.required),
    password: new FormControl('', Validators.required),
  });

  constructor(private route: ActivatedRoute,
    private authenticationService: AuthenticationService,
    private navigationService: NavigationService,
    topbarTitleService: TopbarTitleService) {

    topbarTitleService.title = "ThinkBDW";

    if (this.authenticationService.authenticationValue) {
      this.navigationService.navigate('/');
    }
  }

  submit() {
    if (this.form.valid) {
      const model: AuthenticationModel = <AuthenticationModel>{ username: this.form.value.username.trim(), password: this.form.value.password };
      this.authenticationService.login(model).pipe(first())
        .subscribe(
          () => {
            const returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
            this.navigationService.navigate(returnUrl);
          });
    }
  }
}
