<div fxLayoutAlign="center">
  <mat-card fxLayout="column" fxFlex="500px" fxFlex.lt-sm="100%">
    <mat-card-title>Login</mat-card-title>
    <mat-card-content>
      <form [formGroup]="form" fxLayout="column">
        <mat-form-field>
          <input type="text" matInput placeholder="Username" formControlName="username" autofocus>
          <mat-error *ngIf="form.hasError('username') && !form.hasError('required')">
            Please enter an username
          </mat-error>
        </mat-form-field>
        <mat-form-field>
          <input type="password" matInput placeholder="Password" formControlName="password">
          <mat-error *ngIf="form.hasError('password') && !form.hasError('password')">
            Please enter a password
          </mat-error>
        </mat-form-field>
      </form>
    </mat-card-content>
    <mat-card-actions>
      <button color="primary" mat-raised-button (click)="submit()">LOGIN</button>
    </mat-card-actions>
  </mat-card>
</div>
