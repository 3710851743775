import {Component, EventEmitter, Input, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AssetConnectorService} from '@app/routes/asset-management/services/asset-connector.service';
import {UpdateMetadataModel} from '@app/routes/asset-management/models/update-metadata.model';
import {PdfMetadataResult} from '@app/routes/asset-management/models/pdf-metadata.result';

@Component({
    selector: 'app-pdf-metadata',
    templateUrl: './pdf-metadata.component.html',
    styleUrls: ['./pdf-metadata.component.scss']
})
export class PdfMetadataComponent {
    private _id: string;
    private _submitted = new EventEmitter<boolean>();

    public readonly form: FormGroup;

    constructor(formBuilder: FormBuilder,
                private assetConnector: AssetConnectorService
    ) {
        this.form = formBuilder.group(
            {
                startPage: [1, [Validators.required]],
                loops: [true]
            });
    }

    @Output()
    get submitted(): EventEmitter<boolean> {
        return this._submitted;
    }

    @Input()
    set id(value: string) {
        this._id = value;
    }

    @Input()
    set metadata(value: PdfMetadataResult) {
        this.form.patchValue(value);
    }

    submit() {
        if (this.form.valid) {
            const model: UpdateMetadataModel = {pdfMetadata: this.form.value, videoMetadata: null};
            this.assetConnector.updateMetadata(this._id, model)
                .subscribe(() => this._submitted.next(true));
        }
    }
}
