import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable } from '@angular/core';
import { SnackbarService } from '@app/core/services/snackbar.service';

@Injectable({
    providedIn: 'root'
})
export class GlobalErrorHandler implements ErrorHandler {
    constructor(
        private readonly snackbarService: SnackbarService) {
    }

    handleError(error: Error | HttpErrorResponse) {
        if (error instanceof HttpErrorResponse &&
            (<HttpErrorResponse>error).status !== 500 &&
            error.error) {
            if (error.error.errors) {
                const validationError = error.error;
                for (let property in validationError.errors) {
                    if (validationError.errors.hasOwnProperty(property)) {
                        validationError.errors[property].map(item => {
                            this.snackbarService.show(validationError.title, item);
                        });
                    }
                }
            } else {
                this.snackbarService.show('Error', error.error);
            }
        } else {
            this.snackbarService.show('Error', 'Error occured');
            console.log(error);
        }
    }
}
