import {NgfmItem} from './ngfm-item';

export class NgfmFolder extends NgfmItem {
    readonly itemType = 'folder';
    root: string[];
    path: string[];

    constructor(root: string[], path: string[]) {
        super({root, path});
        Object.assign(this, {root, path, fullPath: [...root, ...path]});
        this.name = this.getName(root, path);
        const newId = this.getId(root, path);
        if (newId) {
            this.id = newId;
        }
    }

    private getName(root: string[], path: string[]) {
        let result = path[path.length - 1] || root[root.length - 1] || '';
        if (result.indexOf('|')) {
            result = result.split('|')[0];
        }
        return result;
    }

    private getId(root: string[], path: string[]) {
        let result = path[path.length - 1] || root[root.length - 1] || '';
        if (result.indexOf('|')) {
            result = result.split('|')[1];
        }

        return result;
    }

    toString() {
        return this.fullPath.join('/');
    }

    get parent(): NgfmFolder | null {
        if (!this.path.length) {
            return null;
        }
        return new NgfmFolder(this.root, this.path.slice(0, -1));
    }
}
