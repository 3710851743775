<mat-grid-list [cols]="columns" class="ngfm-grid-list">
  <mat-grid-tile *ngIf="folder.path.length" (click)="navigate(folder.parent)" class="ngfm-tile">
    <mat-grid-tile-header>
      <p>&bull; &bull;</p>
    </mat-grid-tile-header>
    <div class="folder-icon">
      <mat-icon class="mat-icon-2x">folder_open</mat-icon>
    </div>

  </mat-grid-tile>

  <mat-grid-tile (click)="item.isFile? selectionChange(item) : clicked(item)"
                 *ngFor="let item of children$|async trackBy: trackByFn"
                 class="ngfm-tile">

    <mat-grid-tile-header [style.background-color]=" (item.isFile && item.selected) ? '#FF4081' : '#cccccc'">
      <div class="container">
        <div style="float:left;">
          <p [title]="item.name">{{item.name.length > 12 ? item.name.slice(0, 12) + "..." : item.name}}</p>
        </div>
        <div class="header-buttons-container">
          <div class="buttons-container">
            <span class="move-buttons">
              <mat-icon class="mat-icon notranslate material-icons mat-icon-no-color" *ngIf="item.order > (children$ | async)[0].order" (click)="moveUp(item)"
                        role="img" aria-hidden="true">keyboard_arrow_up
              </mat-icon>
              </span>
                <span class="move-buttons"
                      >
                <mat-icon class="mat-icon notranslate material-icons mat-icon-no-color" *ngIf="item.order < (children$ | async)[(children$ | async).length-1].order" (click)="moveDown(item)"
                          role="img" aria-hidden="true">keyboard_arrow_down
              </mat-icon>
            </span>
          </div>
          <ngfm-browser-item-tools [items]="[item]" [config]="(config$|async)"
                                   [isFile]="item.isFile">
          </ngfm-browser-item-tools>
        </div>
      </div>
    </mat-grid-tile-header>
    <div class="ngfm-content">

      <img class="tileImage" [alt]="item.name" [src]="item.thumbnail"
           *ngIf="item.isFile && (item.isVideo||item.isImage)" [title]="item.name">
      <mat-icon class="mat-icon-2x" *ngIf="item.isFolder">folder</mat-icon>

      <div class="ngfm-file-extension" *ngIf="item.isFile && !(item.isVideo||item.isImage)">
        <p>{{item.extension}}</p>
      </div>

    </div>


  </mat-grid-tile>


</mat-grid-list>
