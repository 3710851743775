import { Injectable, NgZone } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { equalsIgnoringCase } from '@app/shared/utils/string.util'

@Injectable({
    providedIn: 'root'
})
export class SnackbarService {
    private snackBarSuccessStyle = 'snackbar-success';
    private snackBarErrorStyle = 'snackbar-error';

    constructor(private readonly snackBar: MatSnackBar,
        private readonly zone: NgZone) {

    }

    show(title: string, text: string) {
        const panelClass =
            equalsIgnoringCase(title, 'success') ?
                this.snackBarSuccessStyle :
                this.snackBarErrorStyle;

        this.zone.run(() => {
            const snackBar = this.snackBar.open(text, null, {
                panelClass: panelClass,
                verticalPosition: 'bottom',
                horizontalPosition: 'center',
                duration: 3000,
            });

            snackBar.onAction().subscribe(() => {
                snackBar.dismiss();
            });
        });
    }
}
