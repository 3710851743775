import {BrowserModule} from '@angular/platform-browser';
import {ErrorHandler, NgModule} from '@angular/core';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import { FlexLayoutModule } from '@angular/flex-layout';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CommonMaterialModule} from './shared/modules/common-material/common-material.module';

import {NavigationComponent} from './core/components/navigation/navigation.component';

import {JwtInterceptor} from './core/interceptors/jwt.interceptor';
import {ErrorInterceptor} from './core/interceptors/error.interceptor';
import {GlobalErrorHandler} from '@app/core/handlers/xhr-error.handler';

import {SidenavService} from './core/services/sidenav.service';

import {ConfirmationDialogComponent} from './shared/components/confirmation-dialog/confirmation-dialog.component';

import {CookieService} from 'ngx-cookie-service';
import {UserContextService} from './shared/services/user-context.service';
import {LoginComponent} from './core/routes/login/login.component';
import {HomeComponent} from './routes/home/home.component';
import {NgfmModule} from '@app/shared/modules/ngfm/ngfm.module';
import {NGFM_CONNECTOR} from '@app/shared/modules/ngfm/connectors/constants';
import {AssetConnectorService} from '@app/routes/asset-management/services/asset-connector.service';
import {GenericSearchModule} from '@app/shared/modules/generic-search/generic-search.module';

@NgModule({
    declarations: [
        AppComponent,
        NavigationComponent,
        LoginComponent,
        HomeComponent,
        ConfirmationDialogComponent,
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        CommonMaterialModule,
        GenericSearchModule,
        NgfmModule.forRoot(),
        FlexLayoutModule,

    ],
    providers: [
        {provide: ErrorHandler, useClass: GlobalErrorHandler},
        {provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true},
        {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true},
        {provide: NGFM_CONNECTOR, useClass: AssetConnectorService},
        SidenavService,
        CookieService,
        UserContextService
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
