<div fxFlexFill #widthSource >
  <div fxLayout="column" fxFlex>
    <div class="ngfm-browser-menu" *ngIf="(config$|async) as config">
      <button mat-button (click)="uploadDialog(folder,[])" *ngIf="config.perms.createFile">
        <mat-icon>cloud_upload</mat-icon>
        {{config?.messages.UPLOAD}}
      </button>
      <button mat-button (click)="mkDir(folder)" *ngIf="config.perms.createFolder">
        <mat-icon>create_new_folder</mat-icon>
        {{config?.messages.CREATE_FOLDER}}
      </button>
      <!--<mat-slide-toggle [(ngModel)]="hideTools">Hide tools</mat-slide-toggle>-->
      <span *ngIf="(!pick) && config.perms.hasAny && this.containsAnyFiles">
        <button mat-button (click)="selectAll()">
            <mat-icon>{{this.isAllFilesSelected ? "check_box_outline_blank" : "check_box"}}</mat-icon>{{this.isAllFilesSelected ? config.messages.DESELECT_ALL: config.messages.SELECT_ALL}}
        </button>
    </span>
      <span *ngIf="(!pick) && config.perms.hasAny">
    <ngfm-browser-item-tools [items]="selectedFiles" [config]="(config$|async)" [isFile]="true"
                             [text]="selectedFiles.length + ' ' + config?.messages.SELECTED"></ngfm-browser-item-tools>
  </span>
    </div>

    <ngfm-breadcrumbs [folder]="folder" (navigated)="this.navigate($event)"></ngfm-breadcrumbs>
    <hr/>
    <div *ngIf="(config$|async) as config" fxFlex>
      <div *ngIf="config.perms.createFile" (drop)="dropped($event)" class="dropzone" fxFlexFill [class.over]="isOver"
           (dragover)="dragOver($event)"
           (dragleave)="dragLeave($event)" fxFlex>
        <div *ngIf="gridCols$|async as gridCols" [class.hide-tools]="hideTools">
          <ngfm-grid-list [children$]="children$" [config$]="config$" [columns]="gridCols" [folder]="folder"
                          (onClicked)="clicked($event)"
                          (onNavigated)="navigate($event)"
                          (onSelectionChanged)="selectionChange($event)"></ngfm-grid-list>
        </div>
      </div>
      <div *ngIf="!config.perms.createFile">
        <div *ngIf="gridCols$|async as gridCols" [class.hide-tools]="hideTools">
          <ngfm-grid-list [children$]="children$" [config$]="config$" [columns]="gridCols" [folder]="folder"
                          (onClicked)="clicked($event)"
                          (onNavigated)="navigate($event)"
                          (onSelectionChanged)="selectionChange($event)"></ngfm-grid-list>
        </div>
      </div>
    </div>
  </div>
</div>
