<div mat-dialog-title>
    {{data.title}}
</div>
<div mat-dialog-content>
    <p *ngIf="data.message">{{data.message}}</p>
    <ng-container *ngIf="!data.message">
        <p *ngFor="let message of data.messages; let i = index">
            {{message}}
        </p>
    </ng-container>
</div>
<div mat-dialog-actions>
    <button mat-button (click)="onNoClick()">No</button>
    <button mat-button [mat-dialog-close]="true">Yes</button>
</div>
