import { Component, OnInit, ViewChild } from '@angular/core';
import { GuardsCheckEnd } from '@angular/router';
import { MatSidenav } from '@angular/material/sidenav';
import { SidenavService } from '@app/core/services/sidenav.service';
import { NavigationGroup } from '../../models/navigation-group';
import { NavigationItem } from '@app/core/models/navigation-item';
import { Constants } from '@app/core/models/constants';
import { UserContextService } from '@app/shared/services/user-context.service';
import { ClientGuard } from '@app/core/guards/client.guard';
import { DevelopmentGuard } from '@app/core/guards/development.guard';
import { TopbarTitleService } from '@app/shared/services/topbar-title.service';
import { filter } from 'rxjs/operators';
import { NavigationService } from '@app/core/services/navigation.service';

export function clone<T>(a: T): T {
  return JSON.parse(JSON.stringify(a));
}

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit {

  @ViewChild('sidenav') public sideNav: MatSidenav;

  menuGroups: NavigationGroup[] = [];

  constructor(public userContextService: UserContextService,
    public clientGuard: ClientGuard,
    public developmentGuard: DevelopmentGuard,
    private sidenavService: SidenavService,
    public topbarTitleService: TopbarTitleService,
    private navigationService: NavigationService) { }

  ngOnInit() {

    this.sidenavService.setSidenav(this.sideNav);

    this.navigationService.Router.events
      .pipe(
        filter(event => event instanceof GuardsCheckEnd)
      )
      .subscribe(() => {
        this.menuGroups = this.prepareMenu();
      });
  }

  openedChange(event: boolean) {
    window.dispatchEvent(new Event('resize'));
  }

  private prepareMenu() {

    if (!this.userContextService.UserContext) {
      return [];
    }

    if (this.userContextService.UserContext.clientId) {
      return this.mapNavigationGroups(Constants.ClientUserNavigations);
    }

    if (!this.clientGuard.client) {
      return this.mapNavigationGroups(Constants.UserNavigations);
    }

    let clientId = this.clientGuard.client.id;

    if (!this.developmentGuard.development) {
      return this.mapNavigationGroups(Constants.ClientNavigations, {
        key: Constants.ClientIdParamKey,
        value: clientId.toString()
      });
    }

    let developmentId = this.developmentGuard.development.id;

    return this.mapNavigationGroups(clone(Constants.DevelopmentNavigations), {
      key: Constants.ClientIdParamKey,
      value: clientId.toString()
    }, {
      key: Constants.DevelopmentIdParamKey,
      value: developmentId.toString()
    });
  }

  private mapNavigationGroups(navigationGroups: NavigationGroup[], ...paramArgs) {

    let navigations = navigationGroups.map(_nav => {

      let nav = Object.assign({}, _nav) as NavigationGroup;

      paramArgs.forEach(param => {
        this.replaceNavigationUrl(nav, param.key, param.value);
      })

      if (nav.items) {
        nav.items.forEach(_item => {
          paramArgs.forEach(param => {
            this.replaceNavigationUrl(_item, param.key, param.value);
          })
        })
      }

      return nav;
    });

    return navigations;
  }

  private replaceNavigationUrl(item: NavigationItem | NavigationGroup, clientIdParamKey: string, clientIdParamValue: string) {
    if (item.url) {
      item.url = item.url.replace(clientIdParamKey, clientIdParamValue);
    }
  }

  returnToLogin(options?: any) {

    this.developmentGuard.clear();
    this.clientGuard.clear();
    this.userContextService.clear();

    this.navigationService.navigatToLogin(options);
  }

  returnToClients() {

    this.developmentGuard.clear();
    this.clientGuard.clear();

    this.navigationService.navigateToHome();
  }

  returnToDevelopments() {

    this.developmentGuard.clear();

    this.navigationService.navigateToClientHome(this.clientGuard.client.id);
  }

  logout() {
    this.returnToLogin();
  }
}
