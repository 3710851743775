<mat-card-title>Video Metadata</mat-card-title>
<mat-card-content>
    <form [formGroup]="form" class="metadata-form">
        <mat-form-field>
            <input type="number" matInput placeholder="Minute" formControlName="minute" required autofocus>
            <mat-error *ngIf="form.hasError('required', 'minute')">
                Please enter video minute
            </mat-error>
        </mat-form-field>
        <mat-form-field>
            <input type="number" matInput placeholder="Second" formControlName="second" required>
            <mat-error *ngIf="form.hasError('required', 'second')">
                Please enter video second
            </mat-error>
        </mat-form-field>
        <mat-checkbox formControlName="loops">Loops</mat-checkbox>
    </form>
</mat-card-content>
<mat-card-actions>
    <button color="primary" mat-raised-button [disabled]="form.invalid" (click)="submit()">Save Changes</button>
</mat-card-actions>
