import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgfmFolder } from '@app/shared/modules/ngfm/models/ngfm-folder';
import { BehaviorSubject, Observable } from 'rxjs';
import { NgfmFile } from '@app/shared/modules/ngfm/models/ngfm-file';
import { NgfmItem } from '@app/shared/modules/ngfm/models/ngfm-item';
import { NgfmConfig } from '@app/shared/modules/ngfm/models/ngfm-config';

@Component({
  selector: 'ngfm-grid-list',
  templateUrl: './ngfm-grid-list.component.html',
  styleUrls: ['./ngfm-grid-list.component.scss']
})
export class NgfmGridListComponent implements OnInit {
  @Input() children$: BehaviorSubject<NgfmFolder[] | NgfmFile[] | NgfmItem[]>;
  @Input() folder: NgfmFolder = new NgfmFolder([], []);
  @Input() columns: number;
  @Input() config$: Observable<NgfmConfig>;
  @Output() onNavigated = new EventEmitter<NgfmFolder>();
  @Output() onSelectionChanged = new EventEmitter<NgfmFile>();
  @Output() onClicked = new EventEmitter<NgfmItem>();
  @Output() onMoveUp = new EventEmitter<NgfmItem>();
  @Output() onMoveDown = new EventEmitter<NgfmItem>();

  constructor() { }

  ngOnInit() {
  }

  trackByFn(idx: number, item: NgfmItem) {
    return item.id;
  }

  navigate(parent: NgfmFolder) {
    this.onNavigated.emit(parent);
  }

  selectionChange(item: NgfmFile) {
    this.onSelectionChanged.emit(item);
  }

  clicked(item: NgfmItem) {
    this.onClicked.emit(item);
  }

  moveUp(item: NgfmItem) {
    event.stopPropagation();
    this.onMoveUp.emit(item);
  }

  moveDown(item: NgfmItem) {
    event.stopPropagation();
    this.onMoveDown.emit(item);
  }
}
