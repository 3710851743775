import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PagedResult } from '@app/shared/models/paged-result';
import { SearchRequestModel } from '@app/shared/models/search-request.model';
import { CloneDevelopmentModel } from '../models/clone-development.model';
import { DevelopmentResult } from '../models/development-result';
import { NewDevelopmentModel } from '../models/new-development.model';
import { UpdateDevelopmentModel } from '../models/update-development.model';



@Injectable({
  providedIn: 'root'
})
export class DevelopmentService {

  constructor(private httpClient: HttpClient) {
  }

  public getByClient(clientId: number) {
    return this.httpClient.post<PagedResult<DevelopmentResult>>(`api/development/search/${clientId}`, {
      currentPage: 0,
      pageSize: 0,
      includeDeleted: false,
      query: '',
      sortName: 'developmentName',
      sortDirection: 'asc'
    });
  }

  public get(id: number) {
    return this.httpClient.get<DevelopmentResult>(`api/development/${id}`);
  }

  public create(clientId: number, model: NewDevelopmentModel) {
    return this.httpClient.post(`api/development/create/${clientId}`, model);
  }

  public delete(id: number) {
    return this.httpClient.delete(`api/development/delete/${id}`);
  }

  public update(id: number, model: UpdateDevelopmentModel) {
    return this.httpClient.put(`api/development/update/${id}`, model);
  }

  public search(clientId: number, request: SearchRequestModel) {
    return this.httpClient.post<PagedResult<DevelopmentResult>>(`api/development/search/${clientId}`, request);
  }

  public lock(id: number) {
    return this.httpClient.get(`api/development/lock/${id}`);
  }

  public unlock(id: number) {
    return this.httpClient.get(`api/development/unlock/${id}`);
  }

  public publish(id: number) {
    return this.httpClient.get(`api/development/publish/${id}`);
  }

  public unpublish(id: number) {
    return this.httpClient.get(`api/development/unpublish/${id}`);
  }

  public validate(id: number) {
    return this.httpClient.get(`api/development/validate/${id}`);
  }

  public getByClientUser() {
    return this.httpClient.get<DevelopmentResult[]>(`api/development/getByClientUser`);
  }

  public clone(id: number, model: CloneDevelopmentModel) {
    return this.httpClient.post<number>(`api/development/clone/${id}`, model);
  }
}
