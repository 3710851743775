import { Component, Output, Input } from '@angular/core';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { EventEmitter } from '@angular/core';
import { GenericSearchModel } from '@app/shared/models/generic-search.model';
import { MatPaginator } from '@angular/material/paginator';

@Component({
  selector: 'generic-search',
  templateUrl: './generic-search.component.html',
  styleUrls: ['./generic-search.component.scss']
})
export class GenericSearchComponent {

  readonly searchForm: FormGroup;

  @Input() placeHolder: string;
  @Input() showIncludeDeletedOption: boolean = true;
  @Input() paginator: MatPaginator;

  @Output() search = new EventEmitter<GenericSearchModel>();

  get SearchModel() {

    let searchModel = new GenericSearchModel();

    searchModel.searchKey = this.searchForm.controls['searchKey'].value;

    return searchModel;
  }

  constructor(formBuilder: FormBuilder) {
    this.searchForm = formBuilder.group({
      searchKey: new FormControl(''),
    });
  }

  clearSearchKey() {
    this.searchForm.controls['searchKey'].setValue('');
  }

  setSearchKey(searchKey: string) {
    this.searchForm.controls['searchKey'].setValue(searchKey);
  }

  onSearch() {
    if(this.paginator != null){
      this.paginator.pageIndex = 0;
    }
    this.search.emit(this.SearchModel);
  }

}
