<mat-sidenav-container>
  <mat-sidenav #sidenav
    mode="side" [disableClose]="true" [opened]="userContextService.UserContext" (openedChange)="openedChange($event)">
    <div *ngIf="userContextService.UserContext">

      <div class="sidenav-header">
        <div class="mat-typography">
          <mat-card>
            <mat-card-title>
              <div class="mat-typography">
                <h3 class="mat-h3" style="margin: 0">{{userContextService.UserContext.userName}}</h3>
                <button mat-button color="accent" (click)="logout()">Logout</button>
              </div>
            </mat-card-title>

            <mat-card-content>

              <div *ngIf="clientGuard.client" >
                <h2 class="mat-h2" style="margin: 0">{{clientGuard.client.clientName}}</h2>
                <button mat-button
                        color="accent" (click)="this.returnToClients()" *ngIf="!developmentGuard.development">
                  Return to Clients
                </button>
              </div>
              <div *ngIf="developmentGuard.development" >
                <h2 class="mat-h2"
                    style="margin: 0">
                  {{developmentGuard.development.developmentName}}
                </h2>
                <button mat-button color="accent" (click)="this.returnToDevelopments()">Return to Developments</button>
              </div>

            </mat-card-content>
          </mat-card>
        </div>


      </div>
      <mat-nav-list>
        <mat-accordion>
          <ng-container *ngFor="let menuGroup of menuGroups">
            <a
              mat-list-item [routerLink]="[menuGroup.url]" routerLinkActive="active" *ngIf="menuGroup.url">{{ menuGroup.title }}</a>
            <ng-container *ngIf="!menuGroup.url">
              <mat-expansion-panel routerLinkActive="active">
                <mat-expansion-panel-header>
                  <mat-panel-title>{{menuGroup.title}}</mat-panel-title>
                </mat-expansion-panel-header>
                <mat-nav-list>
                  <a mat-list-item [routerLink]="[menuItem.url]" *ngFor="let menuItem of menuGroup.items">
                    {{menuItem.title}}
                  </a>
                </mat-nav-list>
              </mat-expansion-panel>
            </ng-container>
          </ng-container>
        </mat-accordion>
      </mat-nav-list>
    </div>
  </mat-sidenav>
  <mat-sidenav-content>


    <div class="container" fxFill fxLayout="column">
      <div fxFlex="100" fxLayout="column">
        <mat-toolbar color="primary" fxFlex="none" style="height: 50px;align-self:flex-start">
          <mat-toolbar-row>
            <div *ngIf="userContextService.UserContext">
              <button mat-icon-button>
                <mat-icon (click)="sidenav.toggle()">menu</mat-icon>
              </button>
            </div>
            <h1>{{topbarTitleService.title}}</h1>
          </mat-toolbar-row>
        </mat-toolbar>
        <div fxFlex>
          <router-outlet ></router-outlet>
        </div>
      </div>

    </div>

  </mat-sidenav-content>
</mat-sidenav-container>
