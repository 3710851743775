import {
  Component,
  OnInit,
  Input,
  HostBinding,
  HostListener,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import {NgfmFile} from '../models/ngfm-file';
import {NgfmFolder} from '../models/ngfm-folder';
import {NgfmConfig} from '../models/ngfm-config';
import {NgfmItem} from '../models/ngfm-item';
import {NgfmDialogService} from '../dialog/ngfm-dialog.service';
import {NgfmApi} from '../connectors/ngfm-api';

@Component({
  selector: 'ngfm-browser-item-tools',
  templateUrl: './ngfm-browser-item-tools.component.html',
  styleUrls: ['./ngfm-browser-item-tools.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NgfmBrowserItemToolsComponent implements OnInit, OnChanges {
  @Input() items: NgfmItem[];
  @Input() isFile = false;
  @Input() text = '';
  @Input() config: NgfmConfig;
  menuItems: any[] = [];
  @HostBinding('class.ngfm-browser-item-tools') public _hostClass = true;
  @Output() selectionChange: EventEmitter<NgfmItem> = new EventEmitter();
  @Output() movedOrDeleted: EventEmitter<any> = new EventEmitter();

  @HostListener('click', ['$event'])
  clicked(ev) {
    ev.stopPropagation();
    ev.stopImmediatePropagation();
  }

  constructor(
    public ngfm: NgfmApi,
    private dialog: NgfmDialogService
  ) {
  }

  ngOnInit() {
    this.buildMenu();
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('items' in changes) {
      this.buildMenu();
    }
  }

  buildMenu() {
    /**
     *
     createFile?= true;
     editFile?= true;
     removeFile?= true;
     createFolder?= true;
     editFolder?= true;
     removeFolder?= true;
     */
    const icons = {
      share: 'share',
      view: 'visibility',
      metadata: 'edit_attributes',
      rename: 'create',
      move: 'move_to_inbox',
      delete: 'delete_forever',
      download: 'cloud_download'
    };
    this.menuItems = (this.isFile ?
      [
        {
          icon: icons.download,
          isMulti: false,
          perms: null,
          disabled: () => false,
          text: this.config.messages.DOWNLOAD,
          action: () => this.ngfm.download(this.items[0] as NgfmFile)
        },
        {
          icon: icons.share,
          isMulti: true,
          perms: 'editFile',
          disabled: () => false,
          text: this.config.messages.SHARE,
          action: () => this.ngfm.shareFiles(this.items as NgfmFile[]).subscribe()
        },
        {
          icon: icons.rename,
          isMulti: false,
          perms: 'editFile',
          disabled: () => false,
          text: this.config.messages.RENAME,
          action: () => this.rename(this.items[0])
        },
        {
          icon: icons.rename,
          isMulti: false,
          perms: 'editFile',
          disabled: () => false,
          text: this.config.messages.CHANGELABEL,
          action: () => this.changelabel(this.items[0])
        },
        {
          icon: icons.rename,
          isMulti: false,
          perms: 'editFile',
          disabled: () => false,
          text: this.config.messages.EDITLINK,
          action: () => this.editLink(this.items[0])
        },
        {
          icon: icons.metadata,
          isMulti: false,
          perms: 'editFile',
          disabled: () => {
            const file = this.items[0] as NgfmFile;
            return !(file.isVideo || file.type == 'application/pdf');
          },
          text: this.config.messages.METADATA,
          action: () => this.metadata(this.items[0] as NgfmFile)
        },
        {
          icon: icons.view,
          isMulti: false,
          perms: 'editFile',
          disabled: () => this.items.length != 1,
          text: this.config.messages.View,
          action: () => this.viewFile(this.items[0] as NgfmFile)
        },

        {
          icon: icons.move,
          isMulti: true,
          perms: 'editFile',
          disabled: () => false,
          text: this.config.messages.MOVE,
          action: () => this.moveFile(this.items as NgfmFile[])
        },
        {
          icon: icons.delete,
          isMulti: true,
          perms: 'removeFile',
          disabled: () => false,
          text: this.config.messages.DELETE,
          action: () => this.rmFiles(this.items as NgfmFile[])
        },
      ] :
      [
        {
          icon: icons.rename,
          isMulti: false,
          perms: 'editFolder',
          disabled: () => false,
          text: this.config.messages.RENAME,
          action: () => this.rename(this.items[0])
        },
        {
          icon: icons.delete,
          isMulti: false,
          perms: 'removeFolder',
          disabled: () => false,
          text: this.config.messages.DELETE,
          action: () => this.rmDirs(this.items as NgfmFolder[])
        },
      ]).filter(menuItem => {
      return this.items.length && ((!menuItem.perms) || this.config.perms[menuItem.perms]) && (menuItem.isMulti || this.items.length === 1) && !menuItem.disabled();
    });
  }

  // TODO: move all actions to the service
  async rename(item: NgfmItem) {
    const newName = await this.dialog.openPrompt(this.config.messages.RENAME, '', item.isFile ? item.name.replace(/\.[^\.]*$/, '') : item.name);
    if (newName) {
      this.ngfm.rename(item, newName).subscribe(() => this.movedOrDeleted.next(true));
    }
  }

  async changelabel(item: NgfmItem) {
    const newName = await this.dialog.openPrompt(this.config.messages.CHANGELABEL, '', item.label);
    if (newName) {
      this.ngfm.changelabel(item, newName).subscribe(() => this.movedOrDeleted.next(true));
    }
  }

  async editLink(item: NgfmItem) {
    const newName = await this.dialog.openPrompt(this.config.messages.EDITLINK, '', item.link);
    if (newName) {
      this.ngfm.editLink(item, newName).subscribe(() => this.movedOrDeleted.next(true));
    }
  }

  rmFiles(files: NgfmFile[]) {
    this.dialog.openOkCancel(this.config.messages.ARE_YOU_SURE).then(confirmed =>
      confirmed && this.ngfm.rmFiles(files).subscribe(() => this.movedOrDeleted.next(true))
    );

  }

  rmDirs(dirs: NgfmFolder[]) {
    this.dialog.openOkCancel(this.config.messages.ARE_YOU_SURE).then(confirmed =>
      confirmed && this.ngfm.rmDirs(this.items as NgfmFolder[]).subscribe()
    );
  }

  moveFile(files: NgfmFile[]) {
    const from = files[0].folder;
    this.ngfm.pickFolder(from.root, from.path).subscribe((to: NgfmFolder) => {
      if (to) {
        this.ngfm.moveFiles(files, from, to).subscribe(() => {
          this.movedOrDeleted.next(true);
        });
      }
    });
  }

  viewFile(item: NgfmItem) {
    if (item.isFile) {
      const file = (item as NgfmFile);
      if (file.isVideo) {
        return this.dialog.openHtml(file.name,
          `<video  width="800px" height="500px" style="{object-fit: contain}" 
            autoplay controls class="img-fluid">
          <source src="${file.download}" type="${file.type}">Your browser does not support HTML5 video.</source>
          </video>`, '800px','800px');
      }
      if (file.isAudio) {
        return this.dialog.openHtml(file.name,
          `<audio src="${file.download}" preload="auto" autoplay controls></audio>`,'800px','100px');
      }
      if (file.isImage) {
        return this.dialog.openHtml(file.name, `<img src="${file.download}" width="800px" height="500px" style="{object-fit: contain}" alt="${file.name}"/>`,'800px','800px');
      }
      this.ngfm.download(item as NgfmFile);
    }
  }

  private metadata(file: NgfmFile) {
    this.dialog.metadataDialog(file).subscribe();
  }
}
