import { Injectable } from '@angular/core';
import { NgfmConnector } from '@app/shared/modules/ngfm/connectors/ngfm-connector';
import {
  HttpClient,
  HttpEventType,
  HttpHeaders,
  HttpRequest,
  HttpResponse,
  HttpUploadProgressEvent
} from '@angular/common/http';
import { NgfmProgress } from '@app/shared/modules/ngfm/connectors/ngfm-progress';
import { NgfmFolder } from '@app/shared/modules/ngfm/models/ngfm-folder';
import { NgfmFile } from '@app/shared/modules/ngfm/models/ngfm-file';
import { NgfmItem } from '@app/shared/modules/ngfm/models/ngfm-item';
import { filter, last, map, share, switchMap } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '@app/shared/components/confirmation-dialog/confirmation-dialog.component';
import { ConfirmationDialogModel } from '@app/shared/models/confirmation-dialog.model';
import { CreateGroupModel } from '@app/routes/asset-management/models/create-group.model';
import { RenameAssetModel } from '@app/routes/asset-management/models/rename-asset.model';
import { ChangeLabelAssetModel } from '@app/routes/asset-management/models/changelabel-asset.model';
import { EditLinkAssetModel } from '@app/routes/asset-management/models/editlink-asset.model';
import { RenameGroupModel } from '@app/routes/asset-management/models/rename-group.model';
import { DeleteAssetResult } from '@app/routes/asset-management/models/delete-asset-result';
import { UpdateMetadataModel } from '@app/routes/asset-management/models/update-metadata.model';
import { MetadataResult } from '@app/routes/asset-management/models/metadata.result';
import { of } from 'rxjs';
import { MoveAssetModel } from '@app/routes/asset-management/models/move-asset.model';
import { DevelopmentGuard } from '@app/core/guards/development.guard';

@Injectable({
  providedIn: 'root'
})
export class AssetConnectorService implements NgfmConnector {

  constructor(private http: HttpClient,
    private developmentGuard: DevelopmentGuard,
    private dialog: MatDialog) {
  }

  getMetadata(id: string) {
    return this.http.get<MetadataResult>(`api/asset/getMetadata/${id}`);
  }

  updateMetadata(id: string, model: UpdateMetadataModel) {
    return this.http.put(`api/asset/updateMetadata/${id}`, model);
  }

  private getDevelopmentId() {
    return this.developmentGuard.development ? this.developmentGuard.development.id : 0;
  }

  ls(folder: NgfmFolder, filters?: any): NgfmProgress {
    let url = `/api/asset/listAll/${this.getDevelopmentId()}`;


    if (folder.name) {
      url = url + '/' + folder.id;
    } else if (folder.parent && folder.parent.name) {
      url = url + '/' + folder.parent.id;
    }

    if (filters && filters.searchText) {
      url = url + `?searchText=${filters.searchText}`;
    }

    const req = new HttpRequest('GET', url, { responseType: 'json', reportProgress: true });
    const httpCall = this.http.request(req).pipe(share());
    const progress = httpCall.pipe(
      filter(evt => evt.type === HttpEventType.UploadProgress),
      map((evt: HttpUploadProgressEvent) =>
        evt.loaded / evt.total
      ),
    );
    const success = httpCall.pipe(
      filter(evt => 'body' in evt),
      map((res: HttpResponse<any>) => res.body),
      map(data => data.map(obj => this.createItem(folder, obj)))
      //map(data => data.sort((a, b) => a.isFolder === b.isFolder ? (a.created > b.created ? -1 : 1) : a.isFolder ? -1 : 1))
    );
    return new NgfmProgress(success, progress);
  }

  private createItem(parent: NgfmFolder, data: any) {
    return data.itemType === 'file' ?
      new NgfmFile(parent, data) :
      new NgfmFolder(parent.root, [...parent.path, data.name + '|' + data.id]);
  }

  share(file: NgfmFile): NgfmProgress {
    return new NgfmProgress(
      this.http.put(`/api/asset/share/${file.id}`, {}).pipe(
        map(() => true)
      )
    );
  }

  mkDir(folder: NgfmFolder): NgfmProgress {
    const model = <CreateGroupModel>{
      developmentId: this.getDevelopmentId(),
      parentId: folder.parent && folder.parent.name ? folder.parent.id : null,
      name: folder.name
    };

    return new NgfmProgress(
      this.http.post('/api/asset/group/create', model).pipe(
        map(() => true)
      )
    );
  }

  moveFiles(files: NgfmFile[], from: NgfmFolder, to: NgfmFolder): NgfmProgress {
    const assets = files.map(i => i.id);
    const moveAssetModel = <MoveAssetModel>{
      developmentId: this.getDevelopmentId(),
      assets: assets,
      toGroupId: to.id == '#root|#path|' ? null : to.id
    };
    return new NgfmProgress(
      this.http.put(`/api/asset/move`, moveAssetModel).pipe(
        map(() => true)
      )
    );
  }

  rename(item: NgfmItem, newName: string): NgfmProgress {
    if (item.isFile) {
      const renameAssetModel = <RenameAssetModel>{ name: newName };
      return new NgfmProgress(
        this.http.put(`/api/asset/rename/${item.id}`, renameAssetModel).pipe(
          map(() => true)
        )
      );
    }

    const renameGroupModel = <RenameGroupModel>{ name: newName };
    return new NgfmProgress(
      this.http.put(`/api/asset/group/rename/${item.id}`, renameGroupModel).pipe(
        map(() => true)
      )
    );
  }

  changelabel(item: NgfmItem, newName: string): NgfmProgress {
    if (item.isFile) {
      const changelabelAssetModel = <ChangeLabelAssetModel>{ label: newName };
      return new NgfmProgress(
        this.http.put(`/api/asset/changelabel/${item.id}`, changelabelAssetModel).pipe(
          map(() => true)
        )
      );
    }
  }

  editLink(item: NgfmItem, newName: string): NgfmProgress {
    if (item.isFile) {
      const editLinkAssetModel = <EditLinkAssetModel>{ link: newName };
      return new NgfmProgress(
        this.http.put(`/api/asset/editLink/${item.id}`, editLinkAssetModel).pipe(
          map(() => true)
        )
      );
    }
  }

  rm(file: NgfmFile): NgfmProgress {
    return new NgfmProgress(
      this.http.delete<DeleteAssetResult[]>(`/api/asset/delete/${file.id}`).pipe(
        switchMap((value) => {
          if (!value) {
            return of(true);
          }

          let messages = [];
          messages.push('Do you confirm the deletion of this data?');
          const mapped = value.map(item => `Owner ${item.assetId} has this asset, its message is ${item.message}`);
          messages = messages.concat(mapped);

          return this.dialog.open(ConfirmationDialogComponent, {
            width: '95vw',
            maxWidth: '95vw',
            maxHeight: '95vh',
            data: <ConfirmationDialogModel>{
              title: 'Warning',
              messages: messages
            }
          }).afterClosed().pipe(
            switchMap((modalResult) => {
              if (!modalResult) {
                return of(false);
              }

              return this.http.delete<DeleteAssetResult[]>(`/api/asset/delete/${file.id}?force=true`)
                .pipe(map(() => true));
            }),
          );
        })));
  }

  rmDir(folder: NgfmFolder): NgfmProgress {
    return new NgfmProgress(
      this.http.delete<DeleteAssetResult[]>(`/api/asset/group/delete/${folder.id}`).pipe(
        switchMap((value) => {
          if (!value) {
            return of(true);
          }

          let messages = [];
          messages.push('Do you confirm the deletion of this data?');
          const mapped = value.map(item => `Owner ${item.assetId} has this asset, its message is ${item.message}`);
          messages = messages.concat(mapped);

          return this.dialog.open(ConfirmationDialogComponent, {
            width: '95vw',
            maxWidth: '95vw',
            maxHeight: '95vh',
            data: <ConfirmationDialogModel>{
              title: 'Warning',
              messages: messages
            }
          }).afterClosed().pipe(
            switchMap((modalResult) => {
              if (!modalResult) {
                return of(false);
              }

              return this.http.delete<DeleteAssetResult[]>(`/api/asset/group/delete/${folder.id}?force=true`)
                .pipe(map(() => true));
            }),
          );
        })));
  }

  uploadFile(file: NgfmFile): NgfmProgress {
    const data: FormData = new FormData();
    data.append('file', file.nativeFile);
    data.append('developmentId', this.getDevelopmentId().toString());
    data.append('label', file.label);


    const groupId = file.folder.id === '#root|#path|' ? null : file.folder.id;
    if (groupId) {
      data.append('groupId', groupId);
    }

    const headers = new HttpHeaders();
    headers.set('Content-Length', String(file.nativeFile.size));
    const req = new HttpRequest('POST', '/api/asset/upload', data, { responseType: 'json', reportProgress: true });
    const progress = this.http.request(req).pipe(
      filter(evt => evt.type === HttpEventType.UploadProgress),
      map((evt: HttpUploadProgressEvent) =>
        evt.loaded / evt.total
      ));
    return new NgfmProgress(
      progress.pipe(
        last(),
        map(() => true)
      ),
      progress
    );
  }
}
