<ngfm-dialog-header [title]="folder.name" [disableClose]="!!this.status"></ngfm-dialog-header>
<div *ngIf="!status">
  <div style="height:0;overflow:hidden;">
    <input type="file" (change)="fileChange($event)" multiple #fileInput>
  </div>
  <div (click)="fileInput.click()" [class.over]="isOver" class="dropzone" (drop)="dropped($event)"
       (dragover)="dragOver($event)"
       (dragleave)="dragLeave($event)">
    <p i18n>Drag&amp;Drop or Click</p>
    <div>
      <i class="fa fa-chevron-circle-down fa-2x"></i>
    </div>
  </div>
</div>
<mat-dialog-content>
    <div class="ngfm-upload-file-list" *ngIf="files.length" fxLayout="row">
        <mat-divider></mat-divider>
        <mat-list>
            <mat-list-item *ngFor="let file of files; index as valIndex;trackBy: trackByFn">
                <p matLine>{{file.name}}</p>
                <p matLine *ngIf="file.isValid">
                    {{file.humanSize.value |number:'1.0-2'}} {{file.humanSize.unit}}
                </p>
                <p matLine>
                    <mat-form-field>
                        <mat-label>Enter display label</mat-label>
                        <input matInput [(ngModel)]="files[valIndex].label" />
                    </mat-form-field>
                </p>
                <!--<p matLine>
                    <mat-form-field>
                        <mat-label>Enter asset link</mat-label>
                        <input matInput [(ngModel)]="files[valIndex].link" />
                    </mat-form-field>
                </p>-->
                <p matLine *ngIf="!file.isValid">
                    <strong i18n>INVALID OR NOT A FILE</strong>
                </p>
                <div matLine>
                    <mat-divider></mat-divider>
                </div>
            </mat-list-item>
        </mat-list>
    </div>
</mat-dialog-content>
<div *ngIf="status">
    <p>{{status.sentCount}} / {{status.fileCount}}</p>
    <mat-progress-bar [value]="status.progressPct$|async"></mat-progress-bar>
</div>
<mat-dialog-actions *ngIf="!status">
    <button mat-raised-button mat-button color="primary" (click)="upload()" [disabled]="!files.length">Upload
    </button>
</mat-dialog-actions>
