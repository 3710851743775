import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { PagedResult } from '@app/shared/models/paged-result';

import { NewClientModel } from '../models/new-client.model';
import { ClientResult } from '../models/client-result';
import { UpdateClientModel } from '../models/update-client.model';
import { SearchRequestModel } from '@app/shared/models/search-request.model';

@Injectable({
  providedIn: 'root'
})
export class ClientService {

  constructor(private httpClient: HttpClient) {
  }

  public get(id: number) {
    return this.httpClient.get<ClientResult>(`api/client/${id}`);
  }

  public create(formData: FormData) {
    return this.httpClient.post('api/client/create', formData);
  }

  public delete(id: number) {
    return this.httpClient.delete(`api/client/delete/${id}`);
  }

  public update(formData: FormData) {
    return this.httpClient.put(`api/client/update`, formData);
  }

  public search(request: SearchRequestModel) {
    return this.httpClient.post<PagedResult<ClientResult>>('api/client/search', request);
  }
}
