import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatButtonModule} from '@angular/material/button';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatIconModule} from '@angular/material/icon';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatListModule} from '@angular/material/list';
import {MatMenuModule} from '@angular/material/menu';
import {FlexLayoutModule} from '@angular/flex-layout';
import {MatCardModule} from '@angular/material/card';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {ReactiveFormsModule} from '@angular/forms';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatTableModule} from '@angular/material/table';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatDialogModule} from '@angular/material/dialog';
import {MaterialFileUploadComponent} from '@app/shared/components/material-file-upload/material-file-upload.component';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatExpansionModule} from '@angular/material/expansion';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatOptionModule } from '@angular/material/core';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatSelectModule } from '@angular/material/select';
import { MatSortModule } from '@angular/material/sort';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatRadioModule} from '@angular/material/radio';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';

@NgModule({
    declarations: [MaterialFileUploadComponent],
    imports: [
        CommonModule,
        MatProgressBarModule,
        MatButtonModule,
        MatIconModule,
        MatCardModule,
        MatListModule
    ],
    exports: [
        CommonModule,
        ReactiveFormsModule,
        MatCheckboxModule,
        MatPaginatorModule,
        MatToolbarModule,
        MatTableModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        MatSnackBarModule,
        MatDialogModule,
        MatSidenavModule,
        MatCardModule,
        MatIconModule,
        MatListModule,
        MatMenuModule,
        MatFormFieldModule,
        MatButtonModule,
        MatInputModule,
        FlexLayoutModule,
        MaterialFileUploadComponent,
        MatExpansionModule,
        MatGridListModule,
        MatOptionModule,
        MatSelectModule,
        MatSortModule,
        MatTabsModule,
        MatTooltipModule,
        MatAutocompleteModule,
        MatButtonToggleModule,
        MatRadioModule,
        MatSlideToggleModule
    ]
})
export class CommonMaterialModule {
}
