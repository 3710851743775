import {NgfmPerms} from './ngfm-perms';

export class NgfmConfig {
    perms?: NgfmPerms = new NgfmPerms();
    /**
     * Target item width as pixels. Amount of columns in the list will be calculated based on this.
     */
    listItemSize? = 180;
    messages? = {
        SHARE: 'Share',
        RENAME: 'Rename',
        METADATA: 'Metadata',
        DELETE: 'Delete',
        MOVE: 'Move',
        CHANGELABEL: 'Change Label',
        EDITLINK: 'Edit Link',
        View: 'View',
        UPLOAD: 'Upload',
        CREATE_FOLDER: 'Create Folder',
        SELECTED: 'selected',
        SELECT_ALL: 'Select All',
        DESELECT_ALL: 'Deselect All',
        PICK: 'Pick',
        DOWNLOAD: 'Download',
        FILE_MOVED: 'File(s) moved.',
        DONE: 'Done.',
        GO_TO: 'Go to',
        TOOLS: 'Tools',
        ARE_YOU_SURE: 'Are you sure?',
        CLOSE: 'Close',
        DOWNLOADING: 'Downloading',
        DOWNLOADED: 'Downloaded'
    }

    constructor(init?: NgfmConfig) {
        Object.assign(this, init || {});
    }
}
